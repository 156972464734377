<template>
    <div class="location-container">
        <nav-bar show-back="1">
            <div class="nav-title">Alamat pengiriman</div>
        </nav-bar>
    </div>
</template>

<script>
import NavBar from "../../../components/navBar";
export default {
  components: { NavBar },
}
</script>

<style lang="less" scoped>

</style>